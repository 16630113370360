import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Carousel, Skeleton } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import clsx from 'clsx';
import { debounce } from 'lodash';
import Image, { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import React, { useCallback, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { isBannerVisible } from '../../../../atoms/isBannerVisible';
import { user } from '../../../../atoms/user';
import {
  EventDetails,
  logEvent,
} from '../../../../hooks/useClickEventTracking';
import { User } from '../../../../utils/interfaces/User';
import ButtonDefault, { ButtonVariants } from '../../../shared/basic/button';
import { ShouldRender } from '../../../shared/basic/ShouldRender';

interface BookImageProps {
  book: string | StaticImageData;
  priority: boolean;
  handleImageLoad: () => void;
}

export interface BookProps {
  id: number;
  name: string;
  webFlowLink?: string;
  link?: string;
  desc: string;
  imageLG: StaticImageData;
  imageSM: StaticImageData;
  slug?: boolean;
}

type CustomCarouselRef = {
  next: () => void;
  prev: () => void;
  goTo: (slide: number) => void;
  autoPlay: (playType?: 'update' | 'leave' | 'blur' | undefined) => void;
  innerSlider: {
    goTo: (slide: number) => void;
  };
};

const BannerImage = React.memo(
  ({ book, handleImageLoad, priority }: BookImageProps) => {
    const screens = useBreakpoint();

    const imageWidth = 1340;
    const imageHeight = 440;

    return (
      <>
        <div className='mx-3 lg:mx-0 rounded-xl '>
          <Image
            src={book}
            alt='BookImage'
            width={imageWidth}
            height={imageHeight}
            loading='eager'
            sizes={
              screens.lg
                ? '100vw'
                : '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
            }
            priority={priority}
            onLoad={handleImageLoad}
            style={{
              display: 'block',
              width: '100%',
              height: 'auto',
            }}
            className='rounded-xl'
          />
        </div>
      </>
    );
  },
);

const HeroSection: React.FC<{ books: BookProps[]; isSchool?: boolean }> = ({
  books,
  isSchool = false,
}) => {
  const [hoveredBookId, setHoveredBookId] = useState<number>(0);
  const [isHovered, setIsHovered] = useState(false);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const isBannerVisibleValue = useRecoilValue<boolean | null>(isBannerVisible);
  const userData: User | undefined = useRecoilValue(user);
  const carouselRef = useRef<CustomCarouselRef | null>(null);
  const screens = useBreakpoint();
  const router = useRouter();

  const handleSlideChange = useCallback((currentSlide: number) => {
    setHoveredBookId(currentSlide);
  }, []);

  const handleMouseEnter = useCallback(
    debounce((index) => {
      setHoveredBookId(index);
      carouselRef.current?.goTo(index);
    }, 300),
    [],
  );

  const handleImageLoad = () => {
    if (!allImagesLoaded) {
      setAllImagesLoaded(true);
    }
  };

  const getSourceValue = (path: string): string => {
    if (path === '/') {
      return 'home';
    } else if (path.includes('books')) {
      return 'books';
    } else if (path.includes('courses')) {
      return 'courses';
    } else if (path.includes('schools')) {
      return 'schools';
    } else if (path.includes('live')) {
      return 'live';
    } else {
      return 'home';
    }
  };

  return (
    <div
      className={clsx(
        'carousel-container transition-all duration-300',
        isBannerVisibleValue && !screens.md
          ? 'md:mt-16'
          : 'mt-0 lg:-mt-4 xl:mt-0',
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='relative  max-h-full'>
        <ShouldRender check={screens.lg && books?.length > 1}>
          <div className='flex absolute cursor-pointer z-30  bottom-[50%] -left-4 transform h-28 -translate-y-[-50%]  lg:translate-y-[50%] items-center next-arrow'>
            <button
              name='back'
              aria-label='Go back'
              onClick={() => {
                handleMouseEnter(hoveredBookId - 1);
              }}
              className='z-10 w-8 h-8 xl:w-9 xl:h-9 rounded-full  disabled:cursor-not-allowed    flex cursor-pointer justify-center bg-gray-500 bg-opacity-40 items-center text-fadedWhite   backdrop-filter backdrop-blur-md   '
            >
              <ChevronLeftIcon className=' w-4 h-4 xl:w-6 xl:h-6' />
            </button>
          </div>
        </ShouldRender>

        <div className='z-10'>
          <ShouldRender check={books?.length}>
            <Carousel
              afterChange={handleSlideChange}
              initialSlide={hoveredBookId}
              adaptiveHeight
              autoplay={isHovered}
              dots={screens.lg ? false : true}
              arrows={false}
              lazyLoad={screens.lg ? 'progressive' : 'ondemand'}
              style={{
                aspectRatio: '1344 / 440',
                height: 'auto',
              }}
              className={clsx(
                !allImagesLoaded && 'hidden',
                ' lg:rounded-2xl  overflow-hidden  ',
              )}
              ref={carouselRef as React.RefObject<CustomCarouselRef>}
            >
              {books.map((book, index) => (
                <div
                  key={`${book.id}-${index}`}
                  className={clsx('flex justify-center w-full')}
                >
                  <div
                    onClick={() => {
                      if (!isSchool) {
                        if (window !== undefined && book?.webFlowLink) {
                          window.open(book.webFlowLink, '_blank');
                        }
                        if (window !== undefined && book?.link && !isSchool) {
                          router.push(book.link);
                        }
                      }
                      const eventParams: EventDetails = {
                        event_name: 'banner_view',
                        source: getSourceValue(router.pathname),
                        source_type: getSourceValue(router.pathname),
                        source_id: null,
                        sub_source: null,
                        sub_source_id: null,
                        unit: 'boolean',
                        value: 1,
                      };
                      logEvent({
                        eventDetails: eventParams,
                        userData: userData,
                        pathname: router.pathname,
                      });
                    }}
                    className='relative cursor-pointer w-full lg:w-full items-center flex-shrink-0'
                    style={{
                      width: '100%',
                      position: 'relative',
                      overflow: 'hidden',
                      aspectRatio: '1344 / 440',
                      height: 'auto',
                      paddingBottom: screens.md ? '26.87%' : '51.28%',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                    >
                      <BannerImage
                        book={!screens.sm ? book.imageSM : book.imageLG}
                        handleImageLoad={handleImageLoad}
                        priority={true}
                      />
                    </div>

                    <ShouldRender
                      check={screens.lg && !isSchool && book.slug === false}
                    >
                      <div className='absolute bottom-[15%] left-[3%]'>
                        <ButtonDefault
                          size={screens.lg ? 4 : 1}
                          rounded={true}
                          variant={ButtonVariants.WHITE}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (window !== undefined && book?.webFlowLink) {
                              window.open(book.webFlowLink, '_blank');
                            }
                            if (window !== undefined && book?.link) {
                              router.push(book.link);
                            }
                            const eventParams: EventDetails = {
                              event_name: 'banner_view',
                              source: getSourceValue(router.pathname),
                              source_type: getSourceValue(router.pathname),
                              source_id: null,
                              sub_source: null,
                              sub_source_id: null,
                              unit: 'boolean',
                              value: 1,
                            };
                            logEvent({
                              eventDetails: eventParams,
                              userData: userData,
                              pathname: router.pathname,
                            });
                          }}
                          className='w-min '
                        >
                          <span className='flex justify-center text-xs gap-x-2 xl:text-base px-2 lg:px-4 text-black whitespace-nowrap'>
                            Learn more
                          </span>
                        </ButtonDefault>
                      </div>
                    </ShouldRender>
                  </div>
                </div>
              ))}
            </Carousel>
          </ShouldRender>
          <ShouldRender check={!books?.length || !allImagesLoaded}>
            <div
              style={{
                aspectRatio: '1344 / 440',
                height: 'auto',
              }}
              className='carousel-skeleton-container '
            >
              {/* Skeleton Wrapper */}
              <div className='relative overflow-hidden'>
                {/* Skeleton for carousel image */}
                <Skeleton.Button
                  active={true}
                  style={{
                    aspectRatio: '1344 / 440',
                    height: 'auto',
                  }}
                  className='carousel-skeleton-image rounded-xl lg:rounded-lg w-full px-3 lg:px-0  '
                ></Skeleton.Button>

                <div className='absolute top-[25%] w-full left-[10%]  md:left-[6%] lg:left-[3%]'>
                  <Skeleton.Button
                    style={{
                      width: screens.lg ? '15vw' : '45vw',
                      height: screens.lg ? '25px' : '15px',
                    }}
                    active
                    size='large'
                    className='rounded-md lg:rounded-lg'
                  />
                </div>

                <ShouldRender check={screens.md}>
                  <div className='absolute top-[40%] lg:top-[35%] w-full left-[10%]  md:left-[6%] lg:left-[3%]'>
                    <Skeleton.Button
                      style={{
                        width: screens.lg ? '25vw' : '55vw',
                        height: screens.lg ? '25px' : '15px',
                      }}
                      active
                      size='large'
                      className='rounded-lg'
                    />
                  </div>
                </ShouldRender>
                <div className='absolute bottom-[15%] left-[10%] md:left-[6%] lg:left-[3%]'>
                  <Skeleton.Button
                    style={{
                      width: screens.lg ? '150px' : '100px',
                      height: screens.lg ? '40px' : '25px',
                    }}
                    active
                    size='large'
                    className='rounded-md lg:rounded-lg'
                  />
                </div>
              </div>
            </div>
          </ShouldRender>
        </div>
        <ShouldRender check={screens.lg && books?.length > 1}>
          <div className='flex absolute cursor-pointer -right-4 bottom-[50%] transform h-28 -translate-y-[-50%] lg:translate-y-[50%] items-center next-arrow'>
            <button
              name='next'
              aria-label='Go next'
              onClick={() => {
                handleMouseEnter(hoveredBookId + 1);
              }}
              className='z-10 w-8 h-8 xl:w-9 xl:h-9 rounded-full  disabled:cursor-not-allowed    flex cursor-pointer justify-center bg-gray-500 bg-opacity-40 items-center text-fadedWhite   backdrop-filter backdrop-blur-md   '
            >
              <ChevronRightIcon className=' w-4 h-4 xl:w-6 xl:h-6 ' />
            </button>
          </div>
        </ShouldRender>
      </div>
    </div>
  );
};

export default HeroSection;
